@media (--mq-from-small) {
	.page-intro {
		box-sizing: border-box;
		padding-right: 33.3333%;
	}
}

@media (min-width: 1280px) {
	.page-intro {
		padding-right: 50%;
	}
}
