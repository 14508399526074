.picture-frame {
	background-position: center;
	background-size: cover;
	display: block;
	position: relative;
}

.picture-frame::before {
	content: "";
	display: block;
}

.picture-frame[data-aspect-ratio="3:2"] {
	padding-top: 66.6667%;
}

.picture-frame__image {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: auto !important;
	height: 100%;
}
