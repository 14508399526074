.primary-navigation {
}

.primary-navigation__list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}

.primary-navigation__list__item {
	flex-grow: 1;
	flex-shrink: 1;
}

.primary-navigation__list__item:not(:first-child) {
	border-left: var(--border-style);
}

.primary-navigation__list__item:not(.primary-navigation__list__item--current) {
	border-bottom: var(--border-style);
}

.primary-navigation__link {
	box-sizing: border-box;
	display: block;
	padding: 1rem;
	text-align: center;
	text-decoration: none;
	width: 100%;
}

.primary-navigation__link:focus,
.primary-navigation__link:hover,
.primary-navigation__list__item--current .primary-navigation__link {
	color: var(--color-red);
}
