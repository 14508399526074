.new-upload {
	margin-bottom: 2rem;
}

.new-upload__item {
	margin-bottom: 1.25rem;
}

.new-upload__link {
	color: var(--color-black);
}

.new-upload__input:not(:checked) ~ .new-upload__link {
	color: rgb(0 0 0 / 0.3);
	text-decoration: underline line-through;
}

.new-upload__input:not(:checked) ~ .new-upload__link:hover {
	text-decoration: line-through;
}

.upload-success .new-upload__link {
	color: var(--color-green);
}

.new-upload__label {
	color: rgb(0 0 0 / 0.3);
	cursor: pointer;
	display: inline-block;
	padding-left: 0.5rem;
}

.new-upload__label::after {
	content: attr(data-remove);
}

.new-upload__input:not(:checked) ~ .new-upload__label::after {
	color: rgb(0 0 0 / 0.3);
	content: attr(data-undo);
}

.new-upload__error-file {
	color: var(--color-black);
	margin-bottom: 0;
	padding-left: 0.5rem;
}

.new-upload__error-message {
	color: var(--color-red);
	margin-bottom: 0;
	padding-left: 0.5rem;
}
