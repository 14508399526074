.input-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: start;
}

@media (--mq-up-to-xsmall) {
	.input-group.input-group--radio-image {
		margin-top: calc(var(--space-xsmall) * -1);
	}
}

@media (--mq-up-to-small) {
	.input-group.input-group--checkbox,
	.input-group.input-group--radio {
		margin-top: 0.875rem;
	}
}

.input-group__checkbox,
.input-group__radio {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	margin-top: var(--space-small);
	position: relative;
}

@media (--mq-from-xsmall) {
	.input-group__checkbox,
	.input-group__radio {
		flex-basis: 33.3333%;
		margin-top: var(--space-medium);
	}
}

@media (--mq-from-medium) {
	.input-group__checkbox,
	.input-group__radio {
		margin-top: calc(var(--space-small) * 2);
	}
}

.input-group__checkbox:first-child,
.input-group__radio:first-child {
	margin-top: 0;
}

@media (--mq-from-xsmall) {
	.input-group__checkbox:nth-child(2),
	.input-group__checkbox:nth-child(3),
	.input-group__radio:nth-child(2),
	.input-group__radio:nth-child(3) {
		margin-top: 0;
	}
}

.input-group__checkbox__label,
.input-group__radio__label {
	display: inline-block;
	padding-right: 12px;
	padding-left: 38px;
	position: relative;
}

@media (--mq-from-wide) {
	.input-group__checkbox__label,
	.input-group__radio__label {
		padding-left: 42px;
	}
}

.input-group__checkbox__label:hover,
.input-group__radio__label:hover {
	cursor: pointer;
}

.input-group__checkbox__label::before,
.input-group__radio__label::before {
	background-color: var(--color-white);
	content: "";
	position: absolute;
	top: 4px;
	left: 0;
	width: 16px;
	height: 16px;
}

@media (--mq-from-wide) {
	.input-group__checkbox__label::before,
	.input-group__radio__label::before {
		top: 5px;
		width: 20px;
		height: 20px;
	}
}

.wp-admin .input-group__checkbox__label::before,
.wp-admin .input-group__radio__label::before {
	top: 1px;
}

@media (--mq-from-wide) {
	.wp-admin .input-group__checkbox__label::before,
	.wp-admin .input-group__radio__label::before {
		top: 2px;
	}
}

.input-group__checkbox__label::after {
	content: "✔";
	display: none;
	font-size: 0.8125rem;
	line-height: 16px;
	position: absolute;
	text-align: center;
	top: 4px;
	left: 0;
	width: 16px;
	height: 16px;
}

@media (--mq-from-wide) {
	.input-group__checkbox__label::after {
		line-height: 20px;
		top: 5px;
		width: 20px;
		height: 20px;
	}
}

.input-group__input:checked + .input-group__checkbox__label::after {
	display: block;
}

.wp-admin .input-group__checkbox__label::after {
	top: 1px;
}

@media (--mq-from-wide) {
	.wp-admin .input-group__checkbox__label::after {
		top: 2px;
	}
}

.input-group__radio__label::before {
	border-radius: 50%;
}

.input-group__radio__label::after {
	background-color: var(--color-black);
	border-radius: 50%;
	content: "";
	display: none;
	position: absolute;
	top: 9px;
	left: 5px;
	width: 6px;
	height: 6px;
}

@media (--mq-from-wide) {
	.input-group__radio__label::after {
		top: 11px;
		left: 6px;
		width: 8px;
		height: 8px;
	}
}

.input-group__input:checked + .input-group__radio__label::after {
	display: block;
}

.wp-admin .input-group__radio__label::after {
	top: 6px;
}

@media (--mq-from-wide) {
	.wp-admin .input-group__radio__label::after {
		top: 8px;
	}
}

.input-group__input {
	opacity: 0;
	position: absolute;
	top: 5px;
	left: 5px;
	width: 1px;
	height: 1px;
}

.input-group__other-input {
	display: none;
	margin-top: var(--space-small);
}

@media (--mq-from-xsmall) {
	.input-group__other-input {
		margin-top: var(--space-medium);
	}
}

@media (--mq-from-medium) {
	.input-group__other-input {
		margin-top: calc(var(--space-small) * 2);
	}
}

.input-group__input--other:checked ~ .input-group__other-input,
.input-group__input--other:checked ~ .input-group__other-input {
	display: block;
}

.input-group__radio-image {
	flex-basis: 48px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-top: var(--space-xsmall);
	margin-right: 0.75rem;
	position: relative;
	width: 48px;
	height: 48px;
}

@media (min-width: 375px) {
	.input-group__radio-image {
		flex-basis: 50px;
		margin-right: var(--space-xsmall);
		width: 50px;
		height: 50px;
	}
}

@media (--mq-from-xsmall) {
	.input-group__radio-image {
		flex-basis: 64px;
		margin-top: 0;
		margin-right: var(--space-xsmall);
		width: 64px;
		height: 64px;
	}
}

@media (min-width: 1280px) {
	.input-group__radio-image {
		flex-basis: 82px;
		margin-right: var(--space-small);
		width: 82px;
		height: 82px;
	}
}

.input-group__radio-image:last-child {
	margin-right: 0;
}

.wp-admin .input-group__radio-image {
	flex-basis: 64px;
	margin-right: var(--space-xsmall);
	width: 64px;
	height: 64px;
}

.input-group__radio-image__label {
	border: 1px solid rgb(0 0 0 / 0.3);
	box-sizing: border-box;
	display: block;
	position: relative;
	user-select: none;
	width: 100%;
	height: 100%;
}

.input-group__radio-image__label img {
	margin: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 24px;
	height: 24px;
}

@media (min-width: 375px) {
	.input-group__radio-image__label img {
		width: 28px;
		height: 28px;
	}
}

@media (--mq-from-small) {
	.input-group__radio-image__label img {
		width: 35px;
		height: 35px;
	}
}

.wp-admin .input-group__radio-image__label img {
	width: 28px;
	height: 28px;
}

.input-group__input:checked + .input-group__radio-image__label {
	border: 1px solid rgb(0 0 0 / 1);
}

.input-group__radio-image__label:hover {
	cursor: pointer;
}
