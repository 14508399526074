.form-header {
	border: 0;
	margin: 0;
	margin-bottom: 2rem;
	padding: 0;
	min-width: auto;
}

@media (--mq-from-xsmall) {
	.form-header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (--mq-from-small) {
	.form-header {
		margin-bottom: 3.5rem;
	}
}

@media (min-width: 1280px) {
	.form-header {
		margin-bottom: 4rem;
	}
}

.form-header.form-header--toggle {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

body:not(.wp-admin) .form__toggle:not(:checked) ~ .form-header {
	margin-bottom: 0;
}

.form-header__title {
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 2rem;
}

@media (--mq-from-small) {
	.form-header__title {
		font-size: 1.25rem;
	}
}

@media (--mq-from-medium) {
	.form-header__title {
		flex-basis: 154px;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 20px;
		margin-bottom: 0;
	}
}

@media (--mq-from-wide) {
	.form-header__title {
		font-size: 1.375rem;
	}
}

@media (--mq-from-wide) {
	.form-header__title {
		flex-basis: 174px;
	}
}

.form-header__title:only-child {
	flex-basis: 100%;
	margin-bottom: 0;
}

.form-header--toggle .form-header__title {
	flex-basis: auto;
}

.form-header__toggle-wrapper {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	text-align: right;
}

.form-header__toggle {
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.2;
	width: max-content;
}

@media (--mq-from-small) {
	.form-header__toggle {
		font-size: 1.25rem;
	}
}

@media (--mq-from-wide) {
	.form-header__toggle {
		font-size: 1.375rem;
	}
}

.form-header__toggle::after {
	content: attr(data-closed);
}

.form__toggle:checked ~ .form-header .form-header__toggle::after {
	content: attr(data-opened);
}

.form-header__toggle:hover {
	cursor: pointer;
	text-decoration: underline;
}

.wp-admin .form-header__toggle {
	display: none !important;
}

@media (--mq-from-medium) {
	.form-header__intro {
		flex-basis: calc(100% - 174px);
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (--mq-from-wide) {
	.form-header__intro {
		flex-basis: calc(100% - 194px);
	}
}
