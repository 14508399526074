.validation-message {
	color: var(--color-green);
	margin-bottom: calc(var(--space-xsmall));
}

@media (--mq-from-small) {
	.validation-message {
		margin-bottom: calc(var(--space-small));
	}
}

@media (--mq-from-medium) {
	.validation-message {
		margin-bottom: calc(var(--space-medium));
	}
}

.validation-message.validation-message--error {
	color: var(--color-red);
}
