@keyframes flash-file-button {
	0% {
		background-color: var(--color-white);
	}

	100% {
		background-color: rgb(255 255 255 / 0.25);
	}
}

.file-button {
	background-color: rgb(255 255 255 / 0.25);
	border-radius: 0.625rem;
	color: var(--color-black);
	padding: 1.25rem;
	text-decoration: none;
	transition: background-color linear 96ms;
}

.file-button::before {
	content: "📁";
	display: inline-block;
	margin-right: 0.625rem;
	vertical-align: middle;
}

.file-button:hover,
.file-button:focus {
	background-color: var(--color-white);
}

.file-button:focus {
	outline: 0;
}

:target .file-button.anchor-flash {
	animation: flash-file-button 360ms linear 480ms;
	animation-iteration-count: 6;
}
