.submit-blocker {
	background-color: rgb(0 0 0 / 0.8);
	display: block;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	bottom: 0;
	transition: opacity 128ms linear;
	width: 0;
	height: 0;
	z-index: -1;
}

.submit-blocker::after {
	color: #ffffff;
	content: "Saving data…";
	font-size: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.submit-blocked .submit-blocker {
	opacity: 1;
	pointer-events: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
}

.submit-blocked {
	pointer-events: none;
}

.submit-blocked input {
	pointer-events: none;
}

.submit-blocked button {
	pointer-events: none;
}
