:root {
	--five-minutes: 0.9375rem;
	--thirty-minutes: calc(var(--five-minutes) * 6);
}

@media (--mq-from-small) {
	.schedule {
		background-image: repeating-linear-gradient(transparent 0, transparent var(--thirty-minutes), rgb(0 0 0) calc(var(--thirty-minutes) + 1px), transparent calc(var(--thirty-minutes) + 2px), transparent calc(2 * var(--thirty-minutes) - 1px), rgb(128 128 128) calc(2 * var(--thirty-minutes)));
		display: flex;
		margin: 0 auto var(--thirty-minutes);
		padding-top: var(--thirty-minutes);
	}
}

.schedule__legend {
	display: flex;
	flex-direction: column;
	position: relative;
	top: calc(-1 * var(--thirty-minutes));
	width: 18rem;
}

@media (--mq-up-to-small) {
	.schedule__legend {
		display: none;
	}
}

.schedule__legend__time {
	box-sizing: border-box;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 13rem;
	padding: 1rem;
	height: calc(2 * var(--thirty-minutes));
}

@media (--mq-from-small) {
	.schedule__grid {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		width: 100%;
		height: calc(2 * var(--thirty-minutes) + 1px);
	}
}

@media (--mq-from-small) {
	.schedule__stage {
		display: grid;
		grid-gap: 0;
		grid-auto-rows: var(--five-minutes);
		grid-auto-columns: 100%;
		position: relative;
		width: 30%;
		height: 100%;
	}
}

@media (--mq-from-medium) {
	.schedule__stage {
		width: 26%;
	}
}

@media (--mq-from-wide) {
	.schedule__stage {
		width: 24%;
	}
}

.schedule__item {
	background-color: black;
	color: white;
	display: block;
	line-height: 1.25;
	margin-bottom: 1px;
	padding: 0.9rem 1rem 1rem;
	text-decoration: none;
}

@media (--mq-from-small) {
	.schedule__item {
		margin: 0;
		overflow: hidden;
		padding: 0.9rem 4rem 1rem 1rem;
		position: relative;
	}
}

.schedule__item:last-child {
	margin-bottom: 0;
}

@media (--mq-from-small) {
	a.schedule__item:hover {
		background-color: var(--color-accent);
		color: white;
	}
}

.schedule__item--heading {
	background-color: var(--color-grey);
	box-sizing: border-box;
	color: black;
	font-size: 1rem;
	line-height: 1.75;
	padding: 0 1rem;
	text-align: center;
	text-transform: uppercase;
	height: 2rem;
}

@media (--mq-up-to-small) {
	.schedule__item--heading {
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		display: flex;
		margin-bottom: 0;
		justify-content: space-between;
		position: sticky;
		top: 65px;
	}

	.schedule__item--heading span:first-child {
		color: var(--color-accent);
	}
}

@media (--mq-up-to-small) {
	.schedule__item--heading:not(*:first-of-type) {
		margin-top: 2rem;
	}
}

@media (--mq-from-small) {
	.schedule__item--heading {
		font-size: 1.25rem;
		line-height: var(--thirty-minutes);
		padding: 0 1rem;
		position: absolute;
		text-decoration: underline;
		top: calc(-1 * var(--thirty-minutes) - 1px);
		right: 0%;
		left: 0%;
		height: var(--thirty-minutes);
	}

	.schedule__item--heading span:first-child {
		display: none;
	}
}

.schedule__item--heading:hover {
	background-color: white;
}

@media (--mq-from-small) {
	.schedule__item__start {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
}

@media (--mq-up-to-small) {
	.schedule__item__start::after {
		content: " - ";
	}
}

@media (--mq-from-small) {
	.schedule__item__end {
		position: absolute;
		right: 1rem;
		bottom: 1rem;
	}
}

.schedule__item__speaker {
	display: block;
	font-weight: 700;
	margin-bottom: 0.5rem;
}

.schedule__item__speaker small {
	font-weight: 400;
	letter-spacing: 0.025em;
	opacity: 0.35;
	text-transform: uppercase;
}

.schedule__item__title {
	display: block;
	font-size: 0.875rem;
	margin-bottom: 0.5rem;
}

*.schedule__item__title:last-of-type {
	margin-bottom: 0;
}

.schedule__item__curator {
	color: var(--color-accent);
	display: block;
	font-size: 0.875rem;
}

@media (--mq-from-small) {
	.schedule__item:hover .schedule__item__curator {
		color: black;
	}
}

.schedule__item__tooltip {
	display: none;
	pointer-events: none;
	z-index: 999999;
}

.schedule__item__tooltip.is-resolved {
	box-shadow: 0.5rem 0.5rem 0 #000000;
	visibility: hidden;
}

@media (--mq-from-medium) {
	.schedule__item__tooltip.is-resolved {
		display: block;
	}
}

.schedule__item__tooltip img {
	display: block;
	max-width: 100%;
}

@media (--mq-from-small) {
	.schedule__grid[data-hours="1"] {
		height: calc(2 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="2"] {
		height: calc(4 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="3"] {
		height: calc(6 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="4"] {
		height: calc(8 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="5"] {
		height: calc(10 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="6"] {
		height: calc(12 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="7"] {
		height: calc(14 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="8"] {
		height: calc(16 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="9"] {
		height: calc(18 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="10"] {
		height: calc(20 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="11"] {
		height: calc(22 * var(--thirty-minutes) + 1px);
	}

	.schedule__grid[data-hours="12"] {
		height: calc(24 * var(--thirty-minutes) + 1px);
	}

	/**
	 * Duration in five minute intervals
	 */
	.schedule__item[data-duration="5"] {
		grid-row-end: span 1;
	}

	.schedule__item[data-duration="10"] {
		grid-row-end: span 2;
	}

	.schedule__item[data-duration="15"] {
		grid-row-end: span 3;
	}

	.schedule__item[data-duration="20"] {
		grid-row-end: span 4;
	}

	.schedule__item[data-duration="25"] {
		grid-row-end: span 5;
	}

	.schedule__item[data-duration="30"] {
		grid-row-end: span 6;
	}

	.schedule__item[data-duration="35"] {
		grid-row-end: span 7;
	}

	.schedule__item[data-duration="40"] {
		grid-row-end: span 8;
	}

	.schedule__item[data-duration="45"] {
		grid-row-end: span 9;
	}

	.schedule__item[data-duration="50"] {
		grid-row-end: span 10;
	}

	.schedule__item[data-duration="55"] {
		grid-row-end: span 11;
	}

	.schedule__item[data-duration="60"] {
		grid-row-end: span 12;
	}

	.schedule__item[data-duration="65"] {
		grid-row-end: span 13;
	}

	.schedule__item[data-duration="70"] {
		grid-row-end: span 14;
	}

	.schedule__item[data-duration="75"] {
		grid-row-end: span 15;
	}

	.schedule__item[data-duration="80"] {
		grid-row-end: span 16;
	}

	.schedule__item[data-duration="85"] {
		grid-row-end: span 17;
	}

	.schedule__item[data-duration="90"] {
		grid-row-end: span 18;
	}

	/**
	 * Start in HH:MM notation relative to start of schedule
	 */
	.schedule__item[data-start="00:00"] {
		grid-row-start: 1;
	}

	.schedule__item[data-start="00:05"] {
		grid-row-start: 2;
	}

	.schedule__item[data-start="00:10"] {
		grid-row-start: 3;
	}

	.schedule__item[data-start="00:15"] {
		grid-row-start: 4;
	}

	.schedule__item[data-start="00:20"] {
		grid-row-start: 5;
	}

	.schedule__item[data-start="00:25"] {
		grid-row-start: 6;
	}

	.schedule__item[data-start="00:30"] {
		grid-row-start: 7;
	}

	.schedule__item[data-start="00:35"] {
		grid-row-start: 8;
	}

	.schedule__item[data-start="00:40"] {
		grid-row-start: 9;
	}

	.schedule__item[data-start="00:45"] {
		grid-row-start: 10;
	}

	.schedule__item[data-start="00:50"] {
		grid-row-start: 11;
	}

	.schedule__item[data-start="00:55"] {
		grid-row-start: 12;
	}

	.schedule__item[data-start="01:00"] {
		grid-row-start: 13;
	}

	.schedule__item[data-start="01:05"] {
		grid-row-start: 14;
	}

	.schedule__item[data-start="01:10"] {
		grid-row-start: 15;
	}

	.schedule__item[data-start="01:15"] {
		grid-row-start: 16;
	}

	.schedule__item[data-start="01:20"] {
		grid-row-start: 17;
	}

	.schedule__item[data-start="01:25"] {
		grid-row-start: 18;
	}

	.schedule__item[data-start="01:30"] {
		grid-row-start: 19;
	}

	.schedule__item[data-start="01:35"] {
		grid-row-start: 20;
	}

	.schedule__item[data-start="01:40"] {
		grid-row-start: 21;
	}

	.schedule__item[data-start="01:45"] {
		grid-row-start: 22;
	}

	.schedule__item[data-start="01:50"] {
		grid-row-start: 23;
	}

	.schedule__item[data-start="01:55"] {
		grid-row-start: 24;
	}

	.schedule__item[data-start="02:00"] {
		grid-row-start: 25;
	}

	.schedule__item[data-start="02:05"] {
		grid-row-start: 26;
	}

	.schedule__item[data-start="02:10"] {
		grid-row-start: 27;
	}

	.schedule__item[data-start="02:15"] {
		grid-row-start: 28;
	}

	.schedule__item[data-start="02:20"] {
		grid-row-start: 29;
	}

	.schedule__item[data-start="02:25"] {
		grid-row-start: 30;
	}

	.schedule__item[data-start="02:30"] {
		grid-row-start: 31;
	}

	.schedule__item[data-start="02:35"] {
		grid-row-start: 32;
	}

	.schedule__item[data-start="02:40"] {
		grid-row-start: 33;
	}

	.schedule__item[data-start="02:45"] {
		grid-row-start: 34;
	}

	.schedule__item[data-start="02:50"] {
		grid-row-start: 35;
	}

	.schedule__item[data-start="02:55"] {
		grid-row-start: 36;
	}

	.schedule__item[data-start="03:00"] {
		grid-row-start: 37;
	}

	.schedule__item[data-start="03:05"] {
		grid-row-start: 38;
	}

	.schedule__item[data-start="03:10"] {
		grid-row-start: 39;
	}

	.schedule__item[data-start="03:15"] {
		grid-row-start: 40;
	}

	.schedule__item[data-start="03:20"] {
		grid-row-start: 41;
	}

	.schedule__item[data-start="03:25"] {
		grid-row-start: 42;
	}

	.schedule__item[data-start="03:30"] {
		grid-row-start: 43;
	}

	.schedule__item[data-start="03:35"] {
		grid-row-start: 44;
	}

	.schedule__item[data-start="03:40"] {
		grid-row-start: 45;
	}

	.schedule__item[data-start="03:45"] {
		grid-row-start: 46;
	}

	.schedule__item[data-start="03:50"] {
		grid-row-start: 47;
	}

	.schedule__item[data-start="03:55"] {
		grid-row-start: 48;
	}

	.schedule__item[data-start="04:00"] {
		grid-row-start: 49;
	}

	.schedule__item[data-start="04:05"] {
		grid-row-start: 50;
	}

	.schedule__item[data-start="04:10"] {
		grid-row-start: 51;
	}

	.schedule__item[data-start="04:15"] {
		grid-row-start: 52;
	}

	.schedule__item[data-start="04:20"] {
		grid-row-start: 53;
	}

	.schedule__item[data-start="04:25"] {
		grid-row-start: 54;
	}

	.schedule__item[data-start="04:30"] {
		grid-row-start: 55;
	}

	.schedule__item[data-start="04:35"] {
		grid-row-start: 56;
	}

	.schedule__item[data-start="04:40"] {
		grid-row-start: 57;
	}

	.schedule__item[data-start="04:45"] {
		grid-row-start: 58;
	}

	.schedule__item[data-start="04:50"] {
		grid-row-start: 59;
	}

	.schedule__item[data-start="04:55"] {
		grid-row-start: 60;
	}

	.schedule__item[data-start="05:00"] {
		grid-row-start: 61;
	}

	.schedule__item[data-start="05:05"] {
		grid-row-start: 62;
	}

	.schedule__item[data-start="05:10"] {
		grid-row-start: 63;
	}

	.schedule__item[data-start="05:15"] {
		grid-row-start: 64;
	}

	.schedule__item[data-start="05:20"] {
		grid-row-start: 65;
	}

	.schedule__item[data-start="05:25"] {
		grid-row-start: 66;
	}

	.schedule__item[data-start="05:30"] {
		grid-row-start: 67;
	}

	.schedule__item[data-start="05:35"] {
		grid-row-start: 68;
	}

	.schedule__item[data-start="05:40"] {
		grid-row-start: 69;
	}

	.schedule__item[data-start="05:45"] {
		grid-row-start: 70;
	}

	.schedule__item[data-start="05:50"] {
		grid-row-start: 71;
	}

	.schedule__item[data-start="05:55"] {
		grid-row-start: 72;
	}

	.schedule__item[data-start="06:00"] {
		grid-row-start: 73;
	}

	.schedule__item[data-start="06:05"] {
		grid-row-start: 74;
	}

	.schedule__item[data-start="06:10"] {
		grid-row-start: 75;
	}

	.schedule__item[data-start="06:15"] {
		grid-row-start: 76;
	}

	.schedule__item[data-start="06:20"] {
		grid-row-start: 77;
	}

	.schedule__item[data-start="06:25"] {
		grid-row-start: 78;
	}

	.schedule__item[data-start="06:30"] {
		grid-row-start: 79;
	}

	.schedule__item[data-start="06:35"] {
		grid-row-start: 80;
	}

	.schedule__item[data-start="06:40"] {
		grid-row-start: 81;
	}

	.schedule__item[data-start="06:45"] {
		grid-row-start: 82;
	}

	.schedule__item[data-start="06:50"] {
		grid-row-start: 83;
	}

	.schedule__item[data-start="06:55"] {
		grid-row-start: 84;
	}

	.schedule__item[data-start="07:00"] {
		grid-row-start: 85;
	}

	.schedule__item[data-start="07:05"] {
		grid-row-start: 86;
	}

	.schedule__item[data-start="07:10"] {
		grid-row-start: 87;
	}

	.schedule__item[data-start="07:15"] {
		grid-row-start: 88;
	}

	.schedule__item[data-start="07:20"] {
		grid-row-start: 89;
	}

	.schedule__item[data-start="07:25"] {
		grid-row-start: 90;
	}

	.schedule__item[data-start="07:30"] {
		grid-row-start: 91;
	}

	.schedule__item[data-start="07:35"] {
		grid-row-start: 92;
	}

	.schedule__item[data-start="07:40"] {
		grid-row-start: 93;
	}

	.schedule__item[data-start="07:45"] {
		grid-row-start: 94;
	}

	.schedule__item[data-start="07:50"] {
		grid-row-start: 95;
	}

	.schedule__item[data-start="07:55"] {
		grid-row-start: 96;
	}

	.schedule__item[data-start="08:00"] {
		grid-row-start: 97;
	}

	.schedule__item[data-start="08:05"] {
		grid-row-start: 98;
	}

	.schedule__item[data-start="08:10"] {
		grid-row-start: 99;
	}

	.schedule__item[data-start="08:15"] {
		grid-row-start: 100;
	}

	.schedule__item[data-start="08:20"] {
		grid-row-start: 101;
	}

	.schedule__item[data-start="08:25"] {
		grid-row-start: 102;
	}

	.schedule__item[data-start="08:30"] {
		grid-row-start: 103;
	}

	.schedule__item[data-start="08:35"] {
		grid-row-start: 104;
	}

	.schedule__item[data-start="08:40"] {
		grid-row-start: 105;
	}

	.schedule__item[data-start="08:45"] {
		grid-row-start: 106;
	}

	.schedule__item[data-start="08:50"] {
		grid-row-start: 107;
	}

	.schedule__item[data-start="08:55"] {
		grid-row-start: 108;
	}
}
