.site-header {
	background-color: var(--color-grey);
	box-sizing: border-box;
	color: var(--color-black);
}

@media (--mq-up-to-small) {
	.site-header {
		border-bottom: var(--border-style);
		box-sizing: border-box;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
	}
}

@media (--mq-from-small) {
	.site-header {
		border-bottom: var(--border-style);
	}
}

.site-header__wrapper {
	display: flex;
	justify-content: space-between;
	/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
	align-items: top;
}

.site-header__home-link,
.site-header__title {
	color: currentColor;
	text-decoration: none;
	text-transform: uppercase;
}

.site-header__home-link,
.site-header__title,
.site-header__welcome-text {
	box-sizing: border-box;
	display: block;
	padding: 0;
}

@media (--mq-up-to-small) {
	.site-header__home-link,
	.site-header__title,
	.site-header__welcome-text {
		line-height: 3.125rem;
		height: 3.125rem;
	}
}

@media (--mq-from-small) {
	.site-header__home-link,
	.site-header__title,
	.site-header__welcome-text {
		flex-basis: 33.3333%;
		flex-grow: 1;
		flex-shrink: 0;
		padding: var(--space-small);
		width: max-content;
	}
}

.site-header__title {
	text-align: center;
}

@media (--mq-up-to-xsmall) {
	.site-header__title {
		padding-left: var(--space-xsmall);
	}
}

@media (--mq-up-to-xsmall) {
	.site-header__home-link {
		display: none;
	}
}

@media (--mq-up-to-small) {
	.site-header__home-link {
		padding-left: var(--space-xsmall);
	}
}

.site-header__welcome-text {
	text-align: right;
}

@media (--mq-up-to-small) {
	.site-header__welcome-text {
		padding-right: var(--space-xsmall);
		padding-left: var(--space-xsmall);
	}
}
