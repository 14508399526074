.faq {
}

.faq__question {
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.2;
	margin: 0;
	text-transform: uppercase;
}

@media (--mq-from-small) {
	.faq__question {
		font-size: 1.25rem;
	}
}

@media (--mq-from-wide) {
	.faq__question {
		font-size: 1.375rem;
	}
}

.faq__question__toggle {
	cursor: pointer;
	display: block;
	padding-right: 120px;
	position: relative;
}

.faq__question__toggle::after {
	content: "(click to open)";
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.2;
	position: absolute;
	text-transform: lowercase;
	top: 0;
	right: 0;
}

@media (--mq-from-small) {
	.faq__question__toggle::after {
		font-size: 1.25rem;
	}
}

@media (--mq-from-wide) {
	.faq__question__toggle::after {
		font-size: 1.375rem;
	}
}

.faq__checkbox:checked ~ .faq__question .faq__question__toggle::after {
	content: "(click to close)";
}

.faq__question__toggle:focus::after,
.faq__question__toggle:hover::after {
	text-decoration: underline;
}

.faq__answer {
	display: none;
	margin-top: 1rem;
}

.faq__checkbox:checked ~ .faq__answer {
	display: block;
}
