.form {
	padding-top: 2rem;
	padding-bottom: calc(var(--space-xsmall) * 2);
}

@media (--mq-from-small) {
	.form {
		padding-bottom: 0;
	}
}

@media (--mq-from-medium) {
	.form {
		padding-bottom: calc(var(--space-medium) * 2);
	}
}

@media (--mq-from-wide) {
	.form {
		font-size: 1rem;
	}
}

@media (min-width: 1280px) {
	.section--extra .form {
		padding-bottom: calc(var(--space-large) * 2);
	}
}

.wp-admin .form {
	padding-bottom: 40px;
}

.form__toggle {
	display: none !important;
}

.form__group {
	border: 0;
	margin: 0 0 var(--space-small) 0;
	padding: 0;
	min-width: auto;
}

@media (--mq-from-medium) and (max-width: 1279px) {
	.form__group {
		max-width: 600px;
	}
}

@media (min-width: 1280px) {
	.form__group {
		margin-bottom: var(--space-medium);
		max-width: 752px;
	}
}

.form__group:only-child {
	margin-bottom: 0;
}

@media (--mq-from-xsmall) {
	.form__field-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.form__toggle:not(:checked) ~ .form__field-group {
	display: none;
}

.wp-admin .form__field-group {
	display: block !important;
}

.form__item {
	margin-bottom: 1.25rem;
}

@media (--mq-from-xsmall) {
	.form__item {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-small) {
	.form__item {
		display: flex;
		margin-bottom: var(--space-medium);
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (min-width: 1280px) {
	.form__item {
		margin-bottom: calc(var(--space-small) * 2);
	}
}

.form__item.form__item--hidden {
	display: none;
}

.wp-admin .form__item.form__item--submit {
	display: none;
}

@media (--mq-up-to-small) {
	.form__item.form__item--mobile-bottom-label {
		margin-bottom: 0.5rem;
	}
}

@media (--mq-up-to-medium) {
	.form__item__info {
		margin-bottom: 0.625rem;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.form__item__info {
		margin-bottom: 0.5rem;
	}
}

@media (--mq-from-small) {
	.form__item__info {
		display: flex;
		flex-basis: 154px;
		flex-grow: 0;
		flex-shrink: 0;
		flex-direction: column;
		margin-right: 20px;
		justify-content: space-between;
		/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
		align-items: space-between;
		min-height: 50px;
	}
}

@media (--mq-from-wide) {
	.form__item__info {
		flex-basis: 174px;
	}
}

@media (--mq-up-to-small) {
	.form__item--mobile-flex-label .form__item__info {
		display: flex;
		margin-bottom: 0.875rem;
		justify-content: space-between;
		align-items: flex-end;
	}
}

@media (--mq-from-small) {
	.form__item__field {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

.form__label,
.form__message {
	display: inline-block;
}

@media (--mq-from-small) {
	.form__label,
	.form__message {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.form__label {
	margin-bottom: 0;
}

@media (--mq-from-small) {
	.form__label {
		margin-bottom: 0.875rem !important;
	}
}

@media (--mq-up-to-small) {
	.form__item--mobile-flex-label .form__label {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		width: max-content;
	}
}

.form__label:hover {
	cursor: pointer;
}

.form__message {
	color: rgb(0 0 0 / 0.7);
	font-size: 0.75rem;
	line-height: 1;
}

@media (--mq-up-to-small) {
	.form__message {
		transform: translateY(-5px);
	}
}

@media (--mq-from-small) {
	.form__message {
		width: 100%;
	}
}

.form__item--invalid .form__message {
	color: var(--color-red);
}

@media (--mq-up-to-small) {
	.form__item--mobile-flex-label .form__message {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		text-align: right;
	}
}

@media (--mq-up-to-small) {
	.form__item--mobile-bottom-label .form__item__info .form__message {
		display: none;
	}
}

.form__item--mobile-bottom-label .form__item__field > .form__message {
	margin-top: 1rem;
}

@media (--mq-from-small) {
	.form__item--mobile-bottom-label .form__item__field > .form__message {
		display: none;
	}
}

.form__input {
	border: 0;
	border-radius: 0;
	box-shadow: none !important;
	box-sizing: border-box;
	display: block;
	margin: 0;
	outline: 0;
	padding: 0 var(--space-xsmall);
	width: 100%;
}

.form__input.form__input--text,
.form__input.form__input--textarea {
	background-color: var(--color-white);
	border: 1px solid rgb(0 0 0 / 0);
}

.form__input.form__input--text:focus,
.form__input.form__input--textarea:focus {
	border: 1px solid rgb(0 0 0 / 0.3);
}

.form__input.form__input--text {
	line-height: 40px;
	height: 40px;
}

@media (--mq-from-small) {
	.form__input.form__input--text {
		line-height: 50px;
		height: 50px;
	}
}

.form__input.form__input--textarea {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	resize: none;
	height: 120px;
}

@media (--mq-from-small) {
	.form__input.form__input--textarea {
		padding-top: 0.8125rem;
		padding-bottom: 0.8125rem;
		height: 150px;
	}
}

.form__item--invalid .form__input {
	border: 1px solid var(--color-red);
}

.form__input[disabled],
.form__input[readonly] {
	color: rgb(0 0 0 / 0.3);
	pointer-events: none;
}

.form__submit {
	appearance: none;
	background: var(--color-black);
	border: 0;
	border-radius: 0;
	color: var(--color-white);
	display: inline-block;
	font-size: 1rem;
	line-height: 50px;
	outline: 0;
	padding: 0 20px;
	text-align: center;
	height: 50px;
	z-index: 99;
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.form__submit {
		box-sizing: border-box;
		width: 160px;
	}
}

@media (--mq-from-wide) {
	.form__submit {
		font-size: 1.125rem;
	}
}

@media (--mq-from-large) {
	.form__submit {
		font-size: 1.375rem;
	}
}

.form__submit:hover {
	background: var(--color-red);
	cursor: pointer;
}

.form__submit:disabled {
	background-color: rgb(0 0 0 / 0.05);
}

.form__hidden-input {
	display: none !important;
	pointer-events: none;
}

.form__select {
	background-color: var(--color-white);
	border: 1px solid rgb(0 0 0 / 0);
	box-sizing: border-box;
	padding: 0 var(--space-xsmall);
	position: relative;
	width: 100%;
}

.form__select::after {
	border-color: var(--color-black) transparent transparent transparent;
	border-style: solid;
	border-width: 7px 5px 0 5px;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	right: 1rem;
	transform: translate(0, -50%);
	transition: transform ease-in-out 128ms;
	vertical-align: middle;
	width: 0;
	height: 0;
}

.form__select__field {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	font-size: 1rem;
	line-height: 40px;
	outline: 0;
	padding: 0 2rem 0 0;
	position: relative;
	text-align: center;
	width: 100%;
	height: 40px;
	z-index: 2;
}

@media (--mq-from-small) {
	.form__select__field {
		line-height: 50px;
		height: 50px;
	}
}

.form__select__field::-ms-expand {
	display: none;
}

.form__select__field:hover {
	cursor: pointer;
}
