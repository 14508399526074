.speaker-rotation {
}

.speaker-rotation__speaker {
	display: none;
	justify-content: center;
	align-items: center;
	width: 100vw;
	min-height: 100vh;
}

.speaker-rotation__speaker.is-active {
	display: flex;
}

.speaker-rotation__speaker__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
}

.speaker-rotation__speaker__info {
	background-color: var(--color-accent);
	color: white;
	display: flex;
	flex-basis: 50%;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
}

.speaker-rotation__speaker__images {
	flex-basis: 50%;
	font-size: 0;
}

.speaker-rotation__speaker__images mr-blur-circle {
	display: inline-block;
	position: relative;
	width: 50%;
}

.speaker-rotation__speaker__name {
	box-sizing: border-box;
	font-size: 4.5rem;
	line-height: 0.9;
	padding: 1rem;
	position: relative;
	text-transform: uppercase;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.speaker-rotation__speaker__city {
	font-size: 2rem;
	position: absolute;
	text-transform: none;
	top: -2rem;
	right: 1rem;
}

.speaker-rotation__speaker__work {
	background-color: #cccccc;
	flex-basis: 50%;
	position: relative;
}

.speaker-rotation__speaker__work::before {
	content: "";
	display: block;
	padding-top: 100%;
}

.speaker-rotation__slideshow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.speaker-rotation__slideshow__slide {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.speaker-rotation__slideshow__slide.is-active {
	display: block;
}
