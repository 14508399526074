.previous-upload {
	list-style: none;
	margin-bottom: 2rem;
	padding: 0;
}

.previous-upload__item {
	display: flex;
	margin-bottom: 1.25rem;
	justify-content: flex-start;
	align-items: center;
}

.previous-upload__input:not(:checked) ~ .previous-upload__link {
	color: rgb(0 0 0 / 0.3);
	text-decoration: underline line-through;
}

.previous-upload__input:not(:checked) ~ .previous-upload__link:hover {
	text-decoration: line-through;
}

.previous-upload__label {
	color: var(--color-red);
	cursor: pointer;
	display: inline-block;
	padding-left: 0.5rem;
}

.previous-upload__label::after {
	content: attr(data-remove);
}

.previous-upload__input:not(:checked) ~ .previous-upload__label::after {
	color: var(--color-black);
	content: attr(data-undo);
}

.previous-upload__input[readonly] ~ .previous-upload__label::after,
.previous-upload__input:disabled ~ .previous-upload__label::after {
	opacity: 0.7;
	pointer-events: none;
}

.previous-upload__img-info {
	margin-right: 10px;
	padding: 0;
}

.previous-upload__text-info {
	height: 2rem;
}
