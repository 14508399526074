.wrapper {
	box-sizing: border-box;
	padding-right: var(--space-xsmall);
	padding-left: var(--space-xsmall);
	width: 100%;
}

@media (--mq-from-small) {
	.wrapper {
		padding-right: var(--space-small);
		padding-left: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.wrapper {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (min-width: 1280px) {
	.wrapper.wrapper--extra {
		padding-right: var(--space-large);
		padding-left: var(--space-large);
	}
}

body.is-showing-overlay .wrapper {
	filter: blur(1.5rem);
}
