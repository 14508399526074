.l-site-container {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	overflow-x: hidden;
	width: 100%;
	min-height: 100vh;
}

@media (--mq-up-to-small) {
	.l-site-container {
		padding-top: 3.125rem;
	}
}

@media (--mq-from-small) {
	.l-site-container {
		border-right: var(--border-style);
		border-left: var(--border-style);
		width: calc(100% - 2.5rem);
	}
}

@media (--mq-from-medium) {
	.l-site-container {
		width: calc(100% - 4.25rem);
	}
}

@media (--mq-from-wide) {
	.l-site-container {
		width: calc(100% - 6.25rem);
	}
}

@media (--mq-from-xlarge) {
	.l-site-container {
		width: 1821px;
	}
}
