.text-grid {
	display: flex;
	flex-wrap: wrap;
	margin-right: -1rem;
	margin-left: -1rem;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(100% + 2rem);
}

@media (--mq-from-small) {
	.text-grid {
		margin-right: -2rem;
		margin-left: -2rem;
		width: calc(100% + 4rem);
	}
}

.text-grid__item {
	box-sizing: border-box;
	padding: 1rem;
	width: 50%;
}

@media (--mq-from-small) {
	.text-grid__item {
		padding: 2rem;
		width: 33.33%;
	}
}

@media (--mq-from-medium) {
	.text-grid__item {
		width: 25%;
	}
}

@media (--mq-from-wide) {
	.text-grid__item {
		width: 20%;
	}
}

@media (--mq-from-large) {
	.text-grid__item {
		width: 16.6667%;
	}
}

.text-grid__column .text-grid__item {
	display: block;
	width: 100% !important;
}

.text-grid__column {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
}

.text-grid__item--var(--color-accent) {
	color: var(--color-accent);
}

.text-grid__item__title {
	display: block;
	font-size: 1.25rem;
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 1rem;
}

@media (--mq-from-medium) {
	.text-grid__item__title {
		font-size: 1.5rem;
	}
}

.text-grid__item__title--big {
	font-size: 2rem;
	line-height: 1;
	text-transform: uppercase;
}

@media (--mq-from-medium) {
	.text-grid__item__title--big {
		font-size: 3rem;
	}
}

.text-grid__item__content {
	line-height: 1.45;
}

@media (--mq-up-to-small) {
	.text-grid__item__content {
		font-size: 0.8125rem;
		line-height: 1.35;
	}
}

.text-grid__item__footer {
	list-style: none;
	margin: -0.25rem;
	padding: 0;
}

@media (--mq-up-to-small) {
	.text-grid__item__footer {
		font-size: 0.8125rem;
		line-height: 1.35;
	}
}

.text-grid__item__footer li {
	display: inline-block;
}

.text-grid__item__footer a {
	padding: 0.25rem;
}

.text-grid__item__tooltip {
	pointer-events: none;
	z-index: 999999;
}

.text-grid__item__tooltip:not(.is-resolved) {
	display: block;
	margin-top: 1.5rem;
}

.text-grid__item__tooltip.is-resolved {
	box-shadow: 0.5rem 0.5rem 0 #000000;
	visibility: hidden;
}

@media (--mq-from-medium) {
	.text-grid__item__tooltip.is-resolved {
		display: block;
	}
}

.text-grid__item__tooltip img {
	display: block;
	max-width: 100%;
}
