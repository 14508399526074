.countdown {
	display: flex;
	margin-right: -1rem;
	margin-left: -1rem;
	padding-bottom: 1rem;
	width: calc(100% + 2rem);
}

@media (--mq-from-small) {
	.countdown {
		margin-right: -2rem;
		margin-left: -2rem;
		padding-bottom: 2rem;
		width: calc(100% + 4rem);
	}
}

.countdown__part {
	border: 1px solid currentcolor;
	border-right: none;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	padding-bottom: 0.5rem;
	text-align: center;
}

@media (--mq-from-xsmall) {
	.countdown__part {
		padding-bottom: 1rem;
	}
}

@media (--mq-from-small) {
	.countdown__part {
		padding-bottom: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.countdown__part {
		padding-bottom: 2rem;
	}
}

.countdown__part:first-child {
	border-left: none;
}

.countdown__countdown {
	display: block;
	font-size: 2.5rem;
	position: relative;
}

@media (--mq-from-xsmall) {
	.countdown__countdown {
		font-size: 5rem;
	}
}

@media (--mq-from-small) {
	.countdown__countdown {
		font-size: 7.5rem;
	}
}

@media (--mq-from-medium) {
	.countdown__countdown {
		font-size: 10rem;
	}
}

@media (--mq-from-wide) {
	.countdown__countdown {
		font-size: 14.5rem;
	}
}

@media (min-width: 1700px) {
	.countdown__countdown {
		font-size: 18.75rem;
	}
}

.countdown__label {
	font-size: 0.875rem;
	text-align: center;
}

@media (--mq-from-small) {
	.countdown__label {
		font-size: 1rem;
	}
}

@media (--mq-from-medium) {
	.countdown__label {
		font-size: 1.125rem;
	}
}
