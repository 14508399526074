.page-meta {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 999;
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.page-meta {
		border-right: var(--border-style);
		border-left: var(--border-style);
		box-sizing: border-box;
		left: 1.25rem;
		width: calc(100% - 2.5rem);
	}
}

@media (--mq-from-medium) {
	.page-meta {
		top: 7.75rem;
		right: calc(2.125rem + 1px);
		bottom: auto;
		left: auto;
		width: 17.5rem;
	}
}

@media (min-width: 1280px) {
	.page-meta {
		width: 22.5rem;
	}
}

@media (--mq-from-wide) {
	.page-meta {
		font-size: 1.0625rem;
		top: 8.125rem;
		right: calc(3.125rem + 1px);
		width: 26.75rem;
	}
}

@media (--mq-from-xlarge) {
	.page-meta {
		right: calc(((100vw - 1821px) / 2) + 1px);
	}
}

.page-meta__info,
.page-meta__validation {
	box-sizing: border-box;
}

@media (--mq-from-medium) {
	.page-meta__info:not(:first-child),
	.page-meta__validation:not(:first-child) {
		margin-top: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.page-meta__info,
	.page-meta__validation {
		padding: var(--space-xsmall);
	}
}

@media (min-width: 1280px) {
	.page-meta__info,
	.page-meta__validation {
		padding: var(--space-small);
	}
}

@media (--mq-from-wide) {
	.page-meta__info,
	.page-meta__validation {
		padding: 1.875rem;
	}
}

.page-meta__info {
	background-color: var(--color-grey);
}

@media (--mq-up-to-medium) {
	.page-meta__info {
		border-top: var(--border-style);
		text-align: center;
	}
}

@media (--mq-from-medium) {
	.page-meta__info {
		background-color: var(--color-white);
	}
}

@media (--mq-from-medium) {
	.page-meta__info + .page-meta__info {
		margin-top: 0;
	}
}

@media (--mq-up-to-medium) {
	.page-meta__info > p {
		display: none;
	}
}

.page-meta__info > p {
	margin-bottom: 1.5rem;
}

.page-meta__info > p > a {
	color: var(--color-black);
	text-decoration: underline;
}

.page-meta__info > p > a:hover {
	text-decoration: none;
}

.page-meta__info.page-meta__info--secondary {
	background-color: var(--color-grey);
}

@media (--mq-up-to-medium) {
	.page-meta__info.page-meta__info--secondary {
		display: none;
	}
}

.page-meta__title {
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 1.5rem;
}

@media (--mq-from-small) {
	.page-meta__title {
		font-size: 1.25rem;
	}
}

@media (--mq-from-wide) {
	.page-meta__title {
		font-size: 1.375rem;
	}
}

.page-meta__validation {
	animation-delay: 2992ms;
	animation-duration: 112ms;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-name: FADE_OUT;
	animation-timing-function: linear;
	color: var(--color-green);
	pointer-events: none;
	user-select: none;
}

@media (--mq-up-to-small) {
	.page-meta__validation {
		display: none;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.page-meta__validation {
		box-sizing: border-box;
		padding: 0.5rem 0 0.5rem var(--space-small);
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		max-width: calc(50% - 80px);
	}
}

@media (--mq-from-medium) {
	.page-meta__validation {
		background-color: var(--color-green);
		color: var(--color-white);
	}
}

.page-meta__validation.page-meta__validation--error {
	background-color: var(--color-red);
}

@media (--mq-up-to-medium) {
	.page-meta__validation__label > span:not(:first-child) {
		display: none;
	}
}

@keyframes FADE_OUT {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
