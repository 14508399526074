.socials {
	display: flex;
	font-size: 0.8125rem;
	list-style: none;
	margin: 0;
	padding: 0 0.5em;
}

.socials__item {
	display: block;
}

.socials__item a {
	color: currentcolor;
	display: block;
	padding: 0.625em 0.5em 0.75em;
	text-decoration: none;
	text-transform: uppercase;
}
