.file-input {
	display: block;
	margin-bottom: 2rem;
	position: relative;
}

@media (--mq-from-wide) {
	.file-input {
		margin-bottom: 2.5rem;
	}
}

.file-input:disabled {
	opacity: 0.4;
	pointer-events: none;
}

.file-input .file-input__input {
	cursor: pointer;
	font-size: 0;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.is-uploading .file-input .file-input__input {
	cursor: default;
	pointer-events: none;
}

.file-input .file-input__input:disabled,
.file-input .file-input__input[readonly] {
	pointer-events: none;
}

.upload-queue {
	display: none;
}

.has-uploads .upload-queue {
	display: block;
}

.file-input__label {
	border: 1px solid rgb(0 0 0 / 0.3);
	border-radius: 0.5rem;
	box-sizing: border-box;
	color: rgb(0 0 0 / 0.5);
	display: block;
	padding: 1.5rem;
	text-align: center;
	transition:
		border ease-in-out 128ms,
		color ease-in-out 128ms,
		box-shadow ease-in-out 128ms;
	width: 100%;
	height: auto;
}

.file-input__input:hover + .file-input__label,
.file-input__input:focus + .file-input__label {
	border: 1px solid rgb(0 0 0 / 0.7);
	box-shadow: 0 0 5px -2px #000000;
	color: rgb(0 0 0 / 0.7);
}

.file-input__input[readonly] + .file-input__label {
	pointer-events: none;
}

@media (--mq-from-small) {
	.form__item:not(.form__item--half) .file-input__label {
		padding: 3.25rem 2.25rem;
	}
}

.file-input.has-error .file-input__label {
	border: 1px solid var(--color-red);
	box-shadow: 0 0 5px -2px var(--color-red);
	color: var(--color-red);
}

.is-uploading .file-input__label {
	cursor: default;
	pointer-events: none;
}

.file-input__label__title::after {
	content: attr(data-clean);
	display: block;
	font-size: 1.125rem;
	margin-bottom: 0.5rem;
}

@media (--mq-from-small) {
	.form__item:not(.form__item--half) .file-input__label__title::after {
		margin-bottom: 1rem;
	}
}

.is-uploading .file-input__label__title::after {
	content: attr(data-uploading);
}

.file-input__label__text::after {
	content: attr(data-clean);
	display: block;
	font-size: 0.75rem;
	line-height: 1.25rem;
	height: 1.25rem;
}

@media (hover: hover) and (pointer: fine) {
	.file-input__label__text::after {
		content: attr(data-clean-drag);
	}
}

.is-uploading .file-input__label__text::after {
	display: none;
}

.file-input__label__bar,
.file-input__label__progress {
	display: none;
	width: 100%;
	height: 1.25rem;
}

.is-uploading .file-input__label__bar,
.is-uploading .file-input__label__progress {
	display: block;
}

.file-input__label__bar {
	background-color: rgb(0 0 0 / 0.05);
	position: relative;
}

.file-input__label__progress {
	background-color: var(--color-green);
	position: absolute;
	top: 0;
	left: 0;
	transform-origin: left center;
	transition: transform ease-in-out 64ms;
	width: 100%;
	height: 100%;
}
