.asset-list {
	list-style: none;
	margin: 0;
	padding: 0;
	max-width: 752px;
}

.asset-list__item {
	border-top: var(--border-style);
	padding-top: var(--space-xsmall);
	padding-bottom: var(--space-xsmall);
}

@media (--mq-from-small) {
	.asset-list__item {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.asset-list__item {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

.asset-list__item:last-child {
	border-bottom: var(--border-style);
}
