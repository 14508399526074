/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.stack {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.stack__item {
	display: block;
	width: 100%;
}

@media (--mq-from-xsmall) {
	.stack[stacked] .stack__item {
		width: 50%;
	}
}

.stack__column .stack__item {
	width: 100% !important;
}

.stack__column {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
}

.stack__column:empty {
	display: none;
}
