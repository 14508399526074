.tickets {
	border-top: 1px solid currentcolor;
	display: flex;
	flex-wrap: wrap;
	margin-right: -1rem;
	margin-left: -1rem;
	justify-content: space-between;
	align-items: stretch;
	width: calc(100% + 2rem);
}

@media (--mq-from-small) {
	.tickets {
		margin-right: -2rem;
		margin-left: -2rem;
		width: calc(100% + 4rem);
	}
}

@media (--mq-from-medium) {
	.tickets {
		flex-wrap: nowrap;
	}
}

.tickets__item {
	border-bottom: 1px solid currentcolor;
	border-left: 1px solid currentcolor;
	box-sizing: border-box;
	flex-basis: 100%;
	flex-grow: 1;
}

@media (--mq-from-xsmall) {
	.tickets__item {
		display: flex;
		flex-basis: 50%;
	}
}

@media (--mq-from-medium) {
	.tickets__item {
		flex-basis: 25%;
	}
}

.tickets__item:first-child {
	border-left: none;
}

.ticket {
	background-color: var(--color-white);
	color: var(--color-black);
	font-size: 1.375rem;
}

@media (--mq-from-xsmall) {
	.ticket {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: stretch;
	}
}

.ticket--disabled {
	filter: none !important;
}

.ticket--disabled .ticket__content > div,
.ticket--disabled .ticket__price > span {
	color: currentcolor;
	filter: blur(3px);
}

.ticket--disabled .ticket__cta {
	pointer-events: none;
	text-decoration: none;
}

.ticket--inverted {
	background-color: var(--color-accent);
	color: white;
}

.ticket__content {
	border-bottom: 1px solid black;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
	font-size: 1.375rem;
	padding: 2rem;
}

.ticket__content > div {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.ticket__title {
	font-size: 2.25rem;
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 0.5rem;
}

.ticket__description {
	line-height: 1.45;
}

.ticket__description p:last-child {
	margin-bottom: 0;
}

.ticket__price {
	border-bottom: 1px solid black;
	flex-basis: 4.75rem;
	line-height: 4.55rem;
	padding: 0 2rem;
	text-align: center;
}

.ticket__cta {
	flex-basis: 4.75rem;
	font-size: 2.25rem;
	line-height: 4.25rem;
	padding: 0 2rem;
	text-align: center;
	text-decoration: underline;
	/* stylelint-disable-next-line declaration-property-value-no-unknown */
	text-decoration-skip: ink;
}
