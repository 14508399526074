@media (--mq-from-small) {
	.l-split {
		display: flex;
	}
}

.l-split__hero {
	background-position: center center;
	background-size: cover;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
}

@media (--mq-from-small) {
	.l-split__hero {
		border-right: 1px solid currentcolor;
		box-sizing: border-box;
		display: flex;
	}
}

.l-split__content {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
}

.l-split__section {
	border-bottom: 1px solid currentcolor;
}

.l-split__section:empty {
	display: none;
}

.l-split__section:last-child {
	border-bottom: 0;
}
