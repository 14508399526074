.newsletter {
	display: block;
	padding: 1rem;
}

@media (--mq-from-small) {
	.newsletter {
		padding: 2rem;
	}
}

.newsletter > p {
	margin-top: 0;
}

.newsletter__form {
	display: block;
	position: relative;
}

.newsletter__message {
	color: black;
	line-height: 1.45;
	margin-top: 1rem;
}

.newsletter__label {
	display: none;
}

.newsletter__input {
	appearance: none;
	border: 1px solid black;
	border-radius: 0;
	box-sizing: border-box;
	display: block;
	font-size: 1rem;
	padding: 0;
	padding-right: 2.75rem;
	padding-left: 0.75rem;
	width: 100%;
	height: 2.5rem;
}

.newsletter__input:focus {
	outline: 0;
}

.newsletter__button {
	appearance: none;
	background-color: var(--color-accent);
	border: 0;
	color: white;
	position: absolute;
	text-align: center;
	top: 0;
	right: 0;
	transition: color 96ms linear 128ms;
	width: 2.5rem;
	height: 2.5rem;
}

.newsletter__input:valid + .newsletter__button {
	color: black;
}

.newsletter--bordered .newsletter__button {
	border: 1px solid black;
	border-left: 0;
	box-sizing: border-box;
	font-size: 0.875rem;
	width: 2rem;
	height: 2rem;
}
