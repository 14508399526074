:root {
	--color-background: #ffffff;
	--color-foreground: #000000;
	--color-accent: rgb(227 227 227);
	--color-black: #000000;
	--color-green: #95d055;
	--color-dark-grey: rgb(183 183 183);
	--color-grey: rgb(227 227 227);
	--color-red: rgb(238 12 33);
	--color-yellow: rgb(254 238 53);
	--color-yellow-light: rgb(255 250 203);
	--color-white: #ffffff;
	--font-sans: "GT America", "gt-america", "Helvetica Neue", sans-serif;
	--font-serif: "Georgia", serif;
	--border-style: 1px solid #000000;
	--space-xsmall: 16px;
	--space-small: 20px;
	--space-medium: 36px;
	--space-large: 44px;
}
