@media (--mq-from-medium) {
	.l-landing {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
}

.l-landing__intro {
	font-size: 0;
}

.l-landing__column {
	box-sizing: border-box;
	display: inline-block;
	font-size: 1rem;
	padding: 2rem 1.25rem;
	text-align: center;
	vertical-align: middle;
	width: 100%;
}

@media (--mq-from-small) {
	.l-landing__column {
		padding: 4rem 3rem;
	}
}

@media (--mq-from-medium) {
	.l-landing__column {
		padding: 6rem 4rem;
		width: 50%;
	}
}

@media (--mq-from-wide) {
	.l-landing__column {
		padding: 4.5rem 6.25rem;
	}
}

@media (--mq-from-large) {
	.l-landing__column {
		padding: 9.375rem 12.5rem;
	}
}

@media (--mq-up-to-medium) {
	.l-landing__column:nth-child(2) {
		border-top: var(--border-style);
	}
}

@media (--mq-from-medium) {
	.l-landing__column:nth-child(2) {
		border-left: var(--border-style);
	}
}

.l-landing__content,
.l-landing__login {
	display: inline-block;
	text-align: left;
	width: 100%;
}

.l-landing__content {
	max-width: 500px;
}

.l-landing__login {
	max-width: 330px;
}

.l-landing__login .login-form {
	padding-bottom: 0;
}

@media (--mq-from-medium) {
	.l-landing__media {
		flex-grow: 1;
		overflow: hidden;
		padding-top: 56.25%;
		position: relative;
	}
}

.l-landing__media__item {
	display: block;
	width: 100%;
	height: auto;
}

@media (--mq-from-medium) {
	.l-landing__media__item {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		height: 100%;
	}

	@supports (object-fit: cover) {
		.l-landing__media__item {
			object-fit: cover;
			width: 100%;
		}
	}
}
