.partners {
	border-top: 1px solid currentcolor;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	justify-content: space-between;
	align-items: stretch;
	max-width: 100%;
}

.partners__item {
	border-bottom: 1px solid currentcolor;
	border-left: 1px solid currentcolor;
	box-sizing: border-box;
	flex-basis: 33.3333%;
	flex-grow: 1;
	flex-shrink: 0;
	height: 4.125rem;
}

.partners__item:hover {
	color: currentcolor;
}

@media (--mq-up-to-small) {
	.partners__item--intro + .partners__item {
		border-left: none;
	}

	.partners__item:nth-child(3n + 2) {
		border-left: none;
	}
}

@media (--mq-from-small) {
	.partners__item {
		flex-basis: 10%;
		height: 4.6875rem;
	}
}

.partners__item:first-child {
	border-left: 0;
}

.partners__item img {
	display: block;
	margin: 0 auto;
	max-width: 7.5rem;
	max-height: 100%;
}

.partners__item--intro {
	flex-grow: 2;
	flex-shrink: 0;
	font-size: 2rem;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	min-width: 100%;
}

@media (--mq-from-small) {
	.partners__item--intro {
		font-size: 2rem;
		min-width: 20%;
	}
}

@media (--mq-from-medium) {
	.partners__item--intro {
		font-size: 3rem;
	}
}

.partners__item--intro span {
	margin-top: -2px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media (--mq-from-medium) {
	.partners__item--intro span {
		margin-top: -3px;
	}
}
