@font-face {
	font-family: "GT America";
	src: url("../fonts/GT-America-Standard-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "GT America";
	src: url("../fonts/GT-America-Standard-Medium.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "GT America";
	src: url("../fonts/GT-America-Condensed-Black-Italic.woff2") format("woff2");
	font-weight: 800;
	font-style: italic;
	font-stretch: condensed;
}
