.mapbox {
	background-color: black;
	display: block;
	position: relative;
	width: 100%;
	min-height: 100%;
}

.mapbox__map {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.mapboxgl-popup-content {
	border: 1px solid black;
	border-radius: 0;
	box-shadow: none;
	padding: 1rem;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
	border-bottom-color: black;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
	border-top-color: black;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
	border-left-color: black;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
	border-right-color: black;
}

.mapbox__popup {
	font-family: var(--font-sans);
	width: 92.5vw;
	min-width: 230px;
	max-width: 280px;
}

.mapbox__popup .hotspot__number {
	display: none;
}
