@media (--mq-from-small) {
	.social-media-asset {
		display: flex;
		align-items: center;
	}
}

.social-media-asset__thumbnail {
	max-width: 240px;
}

@media (--mq-from-small) {
	.social-media-asset__thumbnail {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.social-media-asset__media {
	width: 100%;
	height: auto;
}

@media (--mq-from-small) {
	.social-media-asset__meta {
		flex-grow: 1;
		flex-shrink: 1;
		padding-right: var(--space-small);
		padding-left: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.social-media-asset__meta {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (min-width: 1280px) {
	.social-media-asset__meta {
		padding-right: var(--space-large);
		padding-left: var(--space-large);
	}
}

.social-media-asset__meta__title {
	margin: 0;
}
