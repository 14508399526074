@media (--mq-from-small) {
	.mobile-subnavigation {
		display: none;
	}
}

.mobile-subnavigation:not(*:first-of-type) {
	border-top: 1px solid black;
	margin-bottom: -1px;
}

.mobile-subnavigation__list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
}

.mobile-subnavigation__item {
	border-bottom: 1px solid currentcolor;
	box-sizing: border-box;
	flex-basis: 50%;
}

.mobile-subnavigation__item:nth-child(odd) {
	border-right: 1px solid currentcolor;
}

.mobile-subnavigation__link {
	display: block;
	line-height: 1;
	padding: 8px 1rem;
	text-decoration: none;
}

.mobile-subnavigation__item.current-menu-item .mobile-subnavigation__link {
	color: var(--color-accent);
}
