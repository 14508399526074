.speaker {
}

.speaker__header {
	padding: 1rem;
}

@media (--mq-from-small) {
	.speaker__header {
		padding: 2rem;
	}
}

.speaker__title {
	font-size: 3.75rem;
	letter-spacing: 1px;
	line-height: 0.85;
	margin: 0;
	margin-top: -6px; /* Compensate line-height */
	margin-bottom: 1rem;
	text-transform: uppercase;
	width: 100%;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

@media (--mq-from-small) {
	.speaker__title {
		font-size: 7.5rem;
		margin-top: -12px; /* Compensate line-height */
		margin-bottom: 2rem;
		width: calc(100% - 5rem);
	}
}

@media (--mq-from-large) {
	.speaker__title {
		font-size: 10.5rem;
	}
}

.speaker__meta {
	display: flex;
	flex-direction: column;
	line-height: 1.45;
}

@media (--mq-from-small) {
	.speaker__meta {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-end;
	}
}

@media (--mq-from-medium) {
	.speaker__meta {
		flex-wrap: nowrap;
	}
}

.speaker__intro {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	font-size: 1rem;
	margin-bottom: 0.5rem;
}

@media (--mq-from-small) {
	.speaker__intro {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		font-size: inherit;
	}
}

@media (--mq-from-medium) {
	.speaker__intro {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		margin-bottom: 0;
	}
}

.speaker__city {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	margin-bottom: 0.5rem;
}

@media (--mq-from-small) {
	.speaker__city {
		margin-bottom: 0;
	}
}

@media (--mq-from-medium) {
	.speaker__city {
		text-align: right;
	}
}

.speaker__read-more {
	flex-basis: 0;
	flex-grow: 2;
	flex-shrink: 1;
	text-align: right;
}

@media (--mq-up-to-small) {
	.speaker__read-more {
		display: none;
	}
}

.speaker__work {
	display: block;
}

.speaker__content {
	display: flex;
	padding: 2rem;
}

@media (--mq-up-to-small) {
	.speaker__content {
		flex-wrap: wrap;
		padding: 1rem;
	}
}

.speaker__aside {
	flex-basis: 25%;
	flex-grow: 0;
	flex-shrink: 1;
}

@media (--mq-up-to-small) {
	.speaker__aside {
		flex-basis: 100%;
	}
}

.speaker__links {
	list-style: none;
	margin: -0.125em 0 0;
	padding: 0;
	text-align: right;
}

@media (--mq-up-to-small) {
	.speaker__links {
		margin-top: 0;
		text-align: left;
	}
}

.speaker__links li {
	display: block;
}

.speaker__links a {
	color: var(--color-accent);
	display: block;
	padding: 0.25em;
	text-decoration: none;
}

@media (--mq-from-small) {
	.speaker__links a {
		color: currentcolor;
	}
}

.speaker__links a:hover {
	color: var(--color-accent);
}

.speaker__pic {
	box-sizing: border-box;
	display: block;
	margin-top: 1rem;
	overflow: hidden;
	text-align: right;
	width: 100%;
}

@media (--mq-from-small) {
	.speaker__pic {
		padding-left: 25%;
	}
}

.speaker__pic img {
	display: inline-block;
	width: 100%;
}

.speaker__bio {
	flex-basis: 75%;
	flex-grow: 1;
	flex-shrink: 0;
}

@media (--mq-up-to-small) {
	.speaker__bio {
		flex-basis: 100%;
	}
}

.speaker__bio p {
	line-height: 1.675;
	margin-top: 0;
}
