.image-grid {
	display: block;
	width: 100%;
}

.image-grid__row {
	display: block;
}

@media (--mq-from-small) {
	.image-grid__row {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
	}
}

.image-grid__item {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
}

.image-grid__item img {
	display: block;
	width: 100%;
}
