.lineup {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0 0.5rem 0.5rem;
	justify-content: flex-start;
}

@media (--mq-from-small) {
	.lineup {
		padding-right: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
	}
}

.page-content__row .lineup {
	margin-right: -0.5rem;
	margin-left: -0.5rem;
	padding: 0;
	width: 100%;
}

@media (--mq-from-small) {
	.page-content__row .lineup {
		margin-right: -1rem;
		margin-left: -1rem;
	}
}

.lineup__item {
	box-sizing: border-box;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;
	padding: 0.5rem;
	width: 50%;
}

@media (--mq-from-small) {
	.lineup__item {
		padding: 1rem;
		width: 33.3334%;
	}
}

@media (--mq-from-medium) {
	.lineup__item {
		width: 25%;
	}
}

@media (--mq-from-large) {
	.lineup__item {
		width: 20%;
	}
}

.lineup__item a {
	color: var(--color-black);
	text-decoration: none;
}

.lineup__poster-image {
	background-color: var(--color-accent);
	display: block;
	margin-bottom: 0.625rem;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.lineup__poster-image img {
	display: block;
	max-width: 100%;
}

.lineup__poster-image::before {
	border-right: 1px solid var(--color-grey);
	content: "";
	position: absolute;
	top: -1px;
	right: 100%;
	bottom: -1px;
	transform: translateX(1px) skew(-10deg);
	transform-origin: bottom;
	width: 100%;
}

.lineup__poster-image::after {
	border-left: 1px solid var(--color-grey);
	content: "";
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: 100%;
	transform: translateX(-1px) skew(-10deg);
	transform-origin: top;
	width: 100%;
}

.lineup__poster-image:hover::before,
.lineup__poster-image:hover::after {
	background-color: var(--color-red);
	border-color: var(--color-red);
}

.lineup__item--placeholder .lineup__poster-image::before,
.lineup__item--placeholder .lineup__poster-image::after {
	display: none;
}

.lineup__meta {
	border-bottom: 1px solid currentcolor;
	margin-bottom: 0.625rem;
	padding-bottom: 0.625rem;
}

@media (--mq-from-xsmall) {
	.lineup__meta {
		display: flex;
		align-items: flex-end;
	}
}

.lineup__name {
	display: block;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
}

.lineup__city {
	flex-basis: 0;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 0.8125rem;
	white-space: nowrap;
}

.lineup__intro {
	display: block;
	font-size: 0.875rem;
	line-height: 1.35;
	margin-top: 0.625rem;
}

@media (--mq-up-to-small) {
	.lineup__intro {
		display: none;
		font-size: 0.8125rem;
		margin-top: 0.5rem;
	}
}
