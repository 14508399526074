.section {
	padding-top: var(--space-xsmall);
	padding-bottom: var(--space-xsmall);
	position: relative;
}

@media (--mq-from-small) {
	.section {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.section {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

@media (min-width: 1280px) {
	.section.section--extra {
		padding-top: var(--space-large);
		padding-bottom: var(--space-large);
	}
}

@media (min-width: 1280px) {
	.section:not(.section--extra) + .section.section--extra {
		padding-top: calc(var(--space-large) - var(--space-medium));
	}
}

.section.section--bordered {
	border-bottom: var(--border-style);
	box-sizing: border-box;
	margin-bottom: var(--space-xsmall);
	position: relative;
}

@media (--mq-from-small) {
	.section.section--bordered {
		margin-bottom: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.section.section--bordered {
		margin-bottom: var(--space-medium);
	}
}

@media (min-width: 1280px) {
	.section.section--bordered.section--extra {
		margin-bottom: var(--space-large);
	}
}

.section.section--bordered-top {
	border-top: var(--border-style);
	box-sizing: border-box;
	margin-top: var(--space-xsmall);
	position: relative;
}

@media (--mq-from-small) {
	.section.section--bordered-top {
		margin-top: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.section.section--bordered-top {
		margin-top: var(--space-medium);
	}
}

@media (min-width: 1280px) {
	.section.section--bordered-top.section--extra {
		margin-top: var(--space-large);
	}
}

.section + .section:not(.section--bordered-top) {
	padding-top: 0;
}

.section + .section.section--bordered-top {
	margin-top: 0;
}

.section__title {
	font-size: 1.375rem;
	font-weight: 400;
	line-height: 1.21;
	margin-top: 0;
	margin-bottom: calc(var(--space-xsmall) * 2);
}

@media (--mq-from-small) {
	.section__title {
		font-size: 2.125rem;
		margin-bottom: calc(var(--space-small) * 2);
	}
}

@media (--mq-from-medium) {
	.section__title {
		font-size: 2rem;
		margin-bottom: calc(var(--space-medium) * 2);
	}
}

@media (min-width: 1280px) {
	.section__title {
		font-size: 2.5rem;
	}
}

@media (--mq-from-wide) {
	.section__title {
		font-size: 3rem;
	}
}

.section__title > a {
	color: currentColor;
	text-decoration: none;
}

.section__title > a:hover {
	color: var(--color-red);
}
