body {
	background-color: var(--color-grey);
	background-position: center;
	background-size: cover;
	color: black;
}

@media (max-width: 1699px) {
	body {
		background-image: none !important;
	}
}

body.is-showing-overlay {
	filter: none !important;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
