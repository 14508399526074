body {
	font-family: var(--font-sans);
	font-size: 1rem;
	line-height: 1.61;
}

@media (--mq-from-wide) {
	body {
		font-size: 1.125rem;
	}
}

a {
	color: currentcolor;
	text-decoration: underline;
}

a:hover,
a:focus {
	text-decoration: none;
}

p {
	margin-top: 0;
	margin-bottom: 2rem;
}

@media (--mq-from-wide) {
	p {
		margin-bottom: 2.5rem;
	}
}

p:last-child {
	margin-bottom: 0;
}

p > a:not([class]) {
	color: var(--color-red);
	text-decoration: none;
}

p > a:not([class]):hover {
	text-decoration: underline;
}
