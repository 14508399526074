.program {
}

.program__navigation {
	box-sizing: border-box;
	position: sticky;
	top: 67px;
	width: 100%;
	z-index: 999;
}

@media (--mq-from-small) {
	.program__navigation {
		border-bottom: 1px solid currentcolor;
		font-size: 1.125rem;
		top: 0;
	}
}

@media (--mq-up-to-small) {
	.program__stage {
		margin-top: -133px;
		padding-top: 133px;
	}
}

@media (--mq-from-small) {
	.program__stage {
		margin-top: -40px;
		padding-top: 40px;
	}
}

.program__stage__title {
	background-color: var(--color-black);
	color: var(--color-white);
	margin: -1rem -1rem 1rem;
	padding: 1rem;
	width: 100%;
}

@media (--mq-from-small) {
	.program__stage__title {
		margin: -2rem -2rem 2rem;
		padding: 2rem;
	}
}

.program__stage__title h1 {
	font-weight: inherit;
	margin: 0;
}

.program__stage__info {
	box-sizing: border-box;
	font-size: 1rem;
	line-height: 1.5;
	padding-top: 0.5rem;
}

@media (--mq-from-small) {
	.program__stage__info {
		font-size: 1.375rem;
		padding-top: 1rem;
	}
}

@media (--mq-from-medium) {
	.program__stage__info {
		font-size: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.program__stage__info {
		font-size: 1.8rem;
		line-height: 1.4;
	}
}

.program__stage__info h2,
.program__stage__info h3,
.program__stage__info h4,
.program__stage__info p {
	margin-top: 0;
}
